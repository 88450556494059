/*================================================================================================================
*                                                  Rights
* ==============================================================================================================*/

// User
export const GET_USER_KEY = 'user';
export const GET_USERS_KEY = 'users';
export const UPSERT_USERS_KEY = 'upsertUser';
export const LINK_USER_GOOGLE_ACCOUNT = 'linkUserGoogleAccount';
export const UNLINK_USER_GOOGLE_ACCOUNT = 'unlinkUserGoogleAccount';
export const UPDATE_EMPLOYEE_CREDENTIALS = 'updateEmployeeCredentials';
export const RESET_EMPLOYEE_CREDENTIALS = 'resetEmployeeCredentials';
export const LOGOUT_USERS_KEY = 'logoutUsers';
export const LOGOUT_ALL_USERS_KEY = 'logoutAllUsers';
export const RESET_USER_PASSWORD = 'resetUserPassword';
export const DELETE_USER_KEY = 'deleteUser';

// Right
export const GET_RIGHTS_KEY = 'rights';
export const UPSERT_RIGHT_KEY = 'upsertRight';
export const DELETE_RIGHT_KEY = 'deleteRight';

// Role
export const GET_ROLES_KEY = 'roles';
export const UPSERT_ROLE_KEY = 'upsertRole';
export const DELETE_ROLE_KEY = 'deleteRole';

// Employee
export const GET_EMPLOYEES_KEY = 'employees';
export const UPSERT_EMPLOYEE_PHOTO_KEY = 'upsertEmployeePhoto';

/*================================================================================================================
*                                                    Master Data
* ==============================================================================================================*/

// Division
export const GET_DIVISIONS_KEY = 'divisions';
export const UPSERT_DIVISION_KEY = 'upsertDivision';
export const DELETE_DIVISION_KEY = 'deleteDivision';

// Department
export const GET_DEPARTMENTS_KEY = 'departments';
export const UPSERT_DEPARTMENT_KEY = 'upsertDepartment';
export const DELETE_DEPARTMENT_KEY = 'deleteDepartment';

// Print Server
export const GET_PRINT_SERVERS_KEY = 'printServers';
export const UPSERT_PRINT_SERVER_KEY = 'upsertPrintServer';
export const DELETE_PRINT_SERVER_KEY = 'deletePrintServer';

// Internal System
export const GET_INTERNAL_SYSTEMS_KEY = 'internalSystems';
export const UPSERT_INTERNAL_SYSTEM_KEY = 'upsertInternalSystem';
export const DELETE_INTERNAL_SYSTEM_KEY = 'deleteInternalSystem';

// Company Reference
export const GET_COMPANY_REFERENCES_KEY = 'companyReferences';
export const UPSERT_COMPANY_REFERENCE_KEY = 'upsertCompanyReference';
export const DELETE_COMPANY_REFERENCE_KEY = 'deleteCompanyReference';

// Country
export const GET_COUNTRIES_KEY = 'Countries';
export const UPSERT_COUNTRY_KEY = 'upsertCountry';
export const DELETE_COUNTRY_KEY = 'deleteCountry';

// Device
export const GET_DEVICES_KEY = 'devices';
export const UPSERT_DEVICE_KEY = 'upsertDevice';
export const DELETE_DEVICE_KEY = 'deleteDevice';

// Device Attribute
export const GET_DEVICE_ATTRIBUTES_KEY = 'deviceAttributes';
export const UPSERT_DEVICE_ATTRIBUTE_KEY = 'upsertDeviceAttribute';
export const DELETE_DEVICE_ATTRIBUTE_KEY = 'deleteDeviceAttribute';

// Pay Point
export const GET_PAY_POINTS_KEY = 'payPoints';
export const UPSERT_PAY_POINT_KEY = 'upsertPayPoint';
export const DELETE_PAY_POINT_KEY = 'deletePayPoint';

// Pay Run
export const GET_PAY_RUNS_KEY = 'payRuns';
export const UPSERT_PAY_RUN_KEY = 'upsertPayRun';
export const DELETE_PAY_RUN_KEY = 'deletePayRun';

// Person Type
export const GET_PERSON_TYPES_KEY = 'personTypes';
export const UPSERT_PERSON_TYPE_KEY = 'upsertPersonType';
export const DELETE_PERSON_TYPE_KEY = 'deletePersonType';

/*================================================================================================================
*                                             Dev Tools Master Data
* ==============================================================================================================*/

// Logging
export const GET_LOGS_KEY = 'logs';

// Contact Us
export const GET_CONTACTS_KEY = 'contacts';
export const UPSERT_CONTACT_KEY = 'upsertContact';
export const DELETE_CONTACT_KEY = 'deleteContact';

// News
export const GET_NEWS_KEY = 'news';
export const UPSERT_NEWS_KEY = 'upsertNews';
export const DELETE_NEWS_KEY = 'deleteNews';

// Frequently Asked Question
export const GET_FREQUENTLY_ASKED_QUESTIONS_KEY = 'frequentlyAskedQuestions';
export const UPSERT_FREQUENTLY_ASKED_QUESTION_KEY = 'upsertfrequentlyAskedQuestion';
export const DELETE_FREQUENTLY_ASKED_QUESTION_KEY = 'deletefrequentlyAskedQuestion';

/*================================================================================================================
*                                                    Fresh Service
* ==============================================================================================================*/

export const UPSERT_FRESH_SERVICE_TICKET = 'upsertFreshServiceTicket';

/*================================================================================================================
*                                                    Transactional
* ==============================================================================================================*/

// Print Request
export const GET_PRINT_REQUESTS_KEY = 'printRequests';